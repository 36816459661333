import React from "react";
import { Col, Row } from "react-bootstrap";
import rectangle from "../images/rectangle_icon.svg";
import circle from "../images/circle_icon.svg";

const Loading = () => {
  return (
    <div
      className="formStyles"
      style={{ textAlign: "center", height: "100vh" }}
    >
      <Col lg={2}>
        <Row>
          <p className="headingStyles" style={{ width: "251px" }}>
            Hang tight while we
            <br /> process your order...
          </p>
        </Row>
        <Row className="m-5">
          <Col>
            <img src={rectangle} alt="rectangle" />
            <img src={circle} className="mt-4 ms-1" alt="circle" />
            <img src={circle} className="mt-4 ms-1" alt="circle_img" />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Loading;
