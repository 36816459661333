import React, { useEffect, useState, createContext } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import states from "states-us";
import notification from "../images/notification icon.svg";
import { setUserDetails } from "../redux/User/user.actions";
import { connect, useDispatch } from "react-redux";

export const OrderContext = createContext();

const SignUpForm = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [kits, setKits] = useState(0);
  const [price, setPrice] = useState("");
  const [kitError, setKitError] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [doctor, setDoctor] = useState("");
  const [referrer, setReferrer] = useState("");
  const [subTotal, setSubTotal] = useState({});
  const [isEnabled, setisEnabled] = useState(false);
  const [earlyDiscount, setEarlyDiscount] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const error = (text) => {
    return (
      <>
        <img alt="notify" src={notification} style={{ marginRight: "3px" }} />
        {text}
      </>
    );
  };

  useEffect(() => {
    const enable =
      firstname.length > 0 &&
      lastname.length > 0 &&
      phone.length > 0 &&
      street.length > 0 &&
      city.length > 0 &&
      state.length > 0 &&
      zip.length > 0 &&
      email.length > 0 &&
      kits.length > 0 &&
      kits >= 1 &&
      emailError === "" &&
      kitError === "";
    setisEnabled(enable);

    const validKits = /^\d+$/.test(kits);
    if (validKits) {
      const early_discount = kits * 800;
      setEarlyDiscount(`-$${early_discount.toFixed(2)}`);
      const kit_price = kits * 4000;
      if (kits >= 1 && kits <= 10) {
        const kitPrice = kit_price - early_discount;
        setPrice(`$${kitPrice.toFixed(2)}`);
        setSubTotal({ subTotal: `$${kit_price.toFixed(2)}` });
      } else if (kits > 10) {
        // Pricing between the frontend and backend discount calculations must agree.
        const discount = 300 * (kits - 10);
        const discountedPrice = kit_price - discount - early_discount;
        setPrice(`$${discountedPrice.toFixed(2)}`);
        setSubTotal({
          subTotal: `$${kit_price.toFixed(2)}`,
          discount: `-$${discount.toFixed(2)}`,
        });
      }
    }
    if (kits === undefined || kits === 0 || kits === "" || kits === "0") {
      setKitError("");
      setPrice("");
      setEarlyDiscount("");
      setSubTotal({});
    }
    if (email === "") {
      setEmailError("");
    }
  }, [
    firstname,
    lastname,
    phone,
    street,
    city,
    state,
    zip,
    email,
    kits,
    price,
    emailError,
    isEnabled,
    kitError,
  ]);

  async function handleSubmit() {
    dispatch(props.setUserDetails({ name: `${firstname} ${lastname}`, email }));
    navigate("checkout", {
      // The state data is what will be sent to the backend, and the shape of
      // this data needs to match what the backend uses.
      state: {
        customer: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
        },
        shipping_address: {
          line1: street,
          line2: "",
          city: city,
          state: state,
          postal_code: zip,
          country: "US",
        },
        order: {
          kits: kits,
          price: price,
        },
        extra: {
          doctor: doctor,
          referrer: referrer,
        },
      },
    });
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
    const isemailValid = /^\S+@\S+\.\S+$/.test(e.target.value);
    setIsValid(isemailValid);
    if (isValid) {
      setEmailError("");
    } else {
      setEmailError(error("Please enter a valid email"));
    }
  }

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  function handleKits(e) {
    setKits(e.target.value);
    const valid = /^\d+$/.test(e.target.value);
    if (!valid) {
      setKitError(error("Please a enter a valid number"));
    } else {
      setKitError("");
    }
  }

  return (
    <div>
      <div>
        <Logo />
      </div>
      {/* <Row>
          <Loading />
        </Row> */}

      <Row>
        <p style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
          Early sign ups for the Family Longevity Plan
        </p>
      </Row>
      <div className="formStyles">
        <Col lg={4}>
          <Container>
            <Row className="mb-4">
              <p className="headingStyles">Your contact information</p>
              <p className="reqLabelStyles">Your first name</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your first name"
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Row>
            <Row className="mb-4">
              <p className="reqLabelStyles">Your last name</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your last name"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Row>
            <Row className="mb-4">
              <p className="reqLabelStyles">Your email</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id={
                  emailError !== ""
                    ? "filled-basic outlined-error-helper-text"
                    : ""
                }
                variant="filled"
                placeholder="Enter your email"
                onChange={(e) => handleEmailChange(e)}
                error={emailError !== "" ? true : false}
                helperText={emailError}
              />
            </Row>
            <Row className="mb-4">
              <p className="reqLabelStyles">Your Phone number</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your phone number"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Row>
            <Row className="mb-4 mt-5">
              <p className="headingStyles">Your physical address(U.S. only)</p>
              <p className="reqLabelStyles">Street</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your street"
                onChange={(e) => setStreet(e.target.value)}
              />
            </Row>
            <Row className="mb-4">
              <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                <p className="reqLabelStyles">City</p>
                <TextField
                  fullWidth
                  style={{ background: "#F6F9FC" }}
                  id="filled-basic"
                  variant="filled"
                  placeholder="Enter your city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="ps-0 pe-0">
                <p className="reqLabelStyles">State</p>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    variant="filled"
                  >
                    Select your state
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={state}
                    onChange={(e) => handleStateChange(e)}
                  >
                    <MenuItem disabled value="">
                      <em>Select your state</em>
                    </MenuItem>
                    {states.map((state) => {
                      return (
                        <MenuItem key={state.name} value={state.name}>
                          {state.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                <p className="reqLabelStyles">Zip</p>
                <TextField
                  fullWidth
                  style={{ background: "#F6F9FC" }}
                  id="filled-basic"
                  variant="filled"
                  placeholder="Enter your zip"
                  onChange={(e) => setZip(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-4 mt-5">
              <p className="headingStyles">Physician information(optional)</p>
              <p className="labelStyles">Your Physician</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your physician name"
                onChange={(e) => setDoctor(e.target.value)}
              />
            </Row>
            <Row className="mb-4">
              <p className="labelStyles">Referring salesperson</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id="filled-basic"
                variant="filled"
                placeholder="Enter your salesperson name"
                onChange={(e) => setReferrer(e.target.value)}
              />
            </Row>
            <Row className="mb-4">
              <p className="headingStyles">Your order</p>
              <p className="reqLabelStyles">Number of kits</p>
              <TextField
                style={{ background: "#F6F9FC" }}
                id={
                  kitError !== ""
                    ? "filled-basic outlined-error-helper-text"
                    : ""
                }
                variant="filled"
                placeholder="Enter or choose number of kits"
                onChange={(e) => handleKits(e)}
                error={kitError !== "" ? true : false}
                helperText={kitError}
              />
            </Row>
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="p-0">
                <span
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ fontSize: "17px", fontWeight: 500 }}>
                    Total
                  </strong>
                  (USD)
                </span>
              </Col>
              <Col
                lg={{ span: 5, offset: 3 }}
                md={{ span: 3, offset: 5 }}
                sm={{ span: 4, offset: 4 }}
                xs={{ span: 7, offset: 1 }}
                className="p-0"
                style={{ textAlign: "right" }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "0px",
                  }}
                >
                  {price}
                </span>
              </Col>
            </Row>

            <Row>
              <Col lg={7} md={5} sm={5} xs={9} className="p-0">
                <span style={{ fontSize: "12px", fontWeight: 500 }}>
                  $300 discount per kit ( {">"}10 kits)
                </span>
              </Col>
              <Col
                lg={{ span: 3, offset: 2 }}
                md={{ span: 2, offset: 5 }}
                sm={{ span: 4, offset: 3 }}
                xs={{ span: 3, offset: 0 }}
                className="p-0"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontWeight: 500, fontSize: "12px" }}>
                  {subTotal.discount}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={5} sm={4} xs={9} className="p-0">
                <span style={{ fontSize: "12px", fontWeight: 500 }}>
                  $800 early discount per kit
                </span>
              </Col>
              <Col
                lg={{ span: 3, offset: 2 }}
                md={{ span: 2, offset: 5 }}
                sm={{ span: 4, offset: 4 }}
                xs={{ span: 3, offset: 0 }}
                className="p-0"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontWeight: 500, fontSize: "12px" }}>
                  {earlyDiscount}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="p-0">
                <span style={{ fontSize: "12px", fontWeight: 500 }}>
                  Subtotal
                </span>
              </Col>
              <Col
                lg={{ span: 3, offset: 5 }}
                md={{ span: 2, offset: 6 }}
                sm={{ span: 4, offset: 4 }}
                xs={{ span: 4, offset: 4 }}
                className="p-0"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontWeight: 500, fontSize: "12px" }}>
                  {subTotal.subTotal}
                </span>
              </Col>
            </Row>

            <div style={{ textAlign: "center" }}>
              <Button
                className="buttonStyles mt-5"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={!isEnabled}
              >
                Proceed to payment
              </Button>
            </div>
          </Container>
        </Col>
      </div>
    </div>
  );
};
export default connect(null, {
  setUserDetails,
})(SignUpForm);
