import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Col } from "react-bootstrap";
import CheckoutForm from "./CheckoutForm";
import Logo from "./Logo";
import Loading from "./Loading";

export function get_stripe_pub_key() {
  // Expect the Stripe public key is available in our env, named like:
  //    REACT_APP_STRIPE_PUB_KEY + "_" + REACT_APP_BUILD_ENV
  // If no REACT_APP_BUILD_ENV, then default to "dev".
  var build_env = process.env.REACT_APP_BUILD_ENV;
  var env = build_env === undefined ? "dev" : build_env;
  return process.env["REACT_APP_STRIPE_PUB_KEY_" + env];
}

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(get_stripe_pub_key());
  }
  return stripePromise;
};
const stripe = getStripe();

const api_endpoint = process.env.REACT_APP_API_URL + "/create-payment-intent";

function StripeElements({ route, naviation }) {
  const [clientSecret, setClientSecret] = useState(null);
  const { state } = useLocation();
  console.log("DEBUG STATE: " + JSON.stringify(state));

  useEffect(() => {
    fetch(api_endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok.");
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        console.log("Error fetching client secret: " + error);
      });
  }, [state]);

  const navigate = useNavigate();
  const handlePaymentSuccess = (paymentIntent) => {
    console.log("is this actually used?");
    // Handle successful payment here
    console.log("Payment successful!", paymentIntent);
    // Redirect to the confirmation page
    // window.location.href = "/confirm";
    navigate("confirm");
  };

  const handlePaymentFailure = (error) => {
    // Handle payment failure here
    console.log("Payment failed:", error);
  };

  if (!clientSecret) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <div>
      <Logo />
      <div className="formStyles">
        <Col lg={5}>
          <Elements options={options} stripe={stripe}>
            <CheckoutForm
              clientSecret={clientSecret}
              handlePaymentSuccess={handlePaymentSuccess}
              handlePaymentFailure={handlePaymentFailure}
            />
          </Elements>
        </Col>
      </div>
    </div>
  );
}

export default StripeElements;
