import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUpForm from "./components/SignUpForm";
import StripeElements from "./components/StripeElements";
import ConfirmationPage from "./components/Confirmation";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<SignUpForm />} />
          <Route path="/checkout" element={<StripeElements />} />
          <Route path="/confirm" element={<ConfirmationPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
