import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";

const CheckoutForm = ({
  clientSecret,
  customer,
  handlePaymentSuccess,
  handlePaymentFailure,
}) => {
  const stripe = useStripe();
  const elements = useElements(); // This apparently contains the paymentIntent value.
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUrl = window.location.href;
  const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
  const returnUrl = `${baseUrl}/confirm`;
  console.log("DEBUG checkout window location: " + returnUrl);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js is not loaded yet, so be sure to diable form submit until then.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = { layout: "tabs" };

  return (
    <div style={{ textAlign: "center" }}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button
        id="submit"
        disabled={isLoading || !stripe || !elements}
        variant="contained"
        size="large"
        onClick={(e) => handleSubmit(e)}
        style={{ margin: "10px" }}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Submit"}
        </span>
      </Button>
      {errorMessage && <div id="payment-message">{errorMessage}</div>}
    </div>
  );
};

export default CheckoutForm;
