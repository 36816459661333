import { USER_DETAILS } from "./user.types";

const INITIAL_STATE = {
  userDetails: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.details,
      };

    default:
      return state;
  }
};

export default reducer;
