import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import descendants from "../images/descendants_logo.svg";

const Heading = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="mt-4 ms-4 me-4 mb-4"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Row>
        <Col lg={3}>
          <img
            alt="descendants_logo"
            src={descendants}
            width="300px"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Heading;
