import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import success from "../images/Success.svg";
import fail from "../images/Fail.svg";
import Logo from "./Logo";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const Confirmation = (props) => {
  const [click, setClick] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const params = searchParams.get("redirect_status");
  const user = useSelector((state) => state.user);

  const body = `mailto:guide@descendantsdna.com?subject=Trouble with my early sign up&body=Name: ${user.userDetails.name} , Email: ${user.userDetails.email}
  There was an error when trying to complete payment for the early sign up.`;

  return (
    <div>
      <Logo />
      <div className="formStyles" style={{ textAlign: "center" }}>
        <Col lg={4}>
          <Row>
            <p className="headingStyles" style={{ fontSize: "20px" }}>
              {params === "succeeded"
                ? "Early sign up confirmed!"
                : "Uh oh.. something went wrong"}
            </p>
          </Row>
          <Row>
            <Col>
              <img
                alt="confirm"
                src={params === "succeeded" ? success : fail}
                width="126px"
                height="126px"
                style={{ margin: "50px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 9, offset: 3 }}>
              <div style={{ textAlign: "justify" }}>
                {params === "succeeded" ? (
                  <div>
                    Your early sign up for the Family
                    <br />
                    Longevity Plan has been processed.
                    <br />
                    <p className="mt-4 mb-5">
                      Please direct any questions to your
                      <br /> sales representative or consult
                      <br />
                      <strong>
                        <u>guide@descendantsdna.com</u>
                      </strong>
                    </p>
                  </div>
                ) : (
                  <p className="mb-5">
                    We had trouble processing your <br />
                    request. Please try again, or contact
                    <br /> support.
                  </p>
                )}
              </div>
            </Col>
          </Row>
          {params === "succeeded" ? (
            <div>
              <Button className="buttonStyles" onClick={() => navigate("/")}>
                Back to homepage
              </Button>
            </div>
          ) : (
            <div>
              <Button className="buttonStyles" onClick={() => navigate("/")}>
                Try again
              </Button>
              <div className="mt-3">
                <Button
                  className="secButtonStyles mb-3"
                  onClick={() => setClick(true)}
                >
                  <a
                    style={{
                      color: "#0E0F10",
                      textDecoration: "none",
                      fontSize: "15px",
                      fontWeight: 500,
                      textTransform: "none",
                    }}
                    href={body}
                  >
                    Contact Support
                  </a>
                </Button>
                {click ? (
                  <p
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    <b>This will email guide@descendantsdna.com</b>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </Col>
      </div>
    </div>
  );
};
export default Confirmation;
